import { Storage } from '@ionic/storage';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './services/config.service';
import { Mall } from './interfaces/interfaces';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private titleService: Title,
    private configProvider: ConfigService,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.configProvider.getMall(window.location.hostname).then((mall:Mall) => {
        this.storage.set('mall', mall)
        this.setTitle(mall.name+" | Sube tus facturas");
      })
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
