import { NgModule } from '@angular/core';
import { NoPreloading,PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: '', loadChildren: './form/form.module#FormPageModule' },
  { path: 'tutorial', loadChildren: './modals/tutorial/tutorial.module#TutorialPageModule' },
  { path: 'store-list', loadChildren: './modals/store-list/store-list.module#StoreListPageModule' }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
