import { Injectable } from '@angular/core';
import { ToastController, AlertController, LoadingController } from '@ionic/angular';
import { Http } from '@angular/http';
import { SERVER_URL } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
// import { promises } from 'fs';

export let CAPTCHA_SITE_KEY = '6LcGmiceAAAAAHDSc7wcF1Qkg96sD-fru2XsGWOv';
export let CAPTCHA_SITE_KEY_STAGING = '6LcutFQdAAAAAPnvvzaiImyqwDmvxWrH3x_qYD4V';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  
  stepPercentage: number = 0;
  storesByContest: any = [];

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public httpAngular: Http,
    public httpClient: HttpClient,
    public loadingController: LoadingController) { }

  //Function to present error message
  async presentErrorToast(message) {
    const toast = await this.toastController.create({
      message: message,
      color: "danger",
      duration: 4000
    });
    toast.present();
  }

  async presentWarningToast(message) {
    const toast = await this.toastController.create({
      message: message,
      color: "warning",
      duration: 8000
    });
    toast.present();
  }

  async showfinalMessage() {
    const alert = await this.alertController.create({
      header: '¡Gracias por participar!',
      message: '<br>Sigue registrando tus facturas para tener mayores posibilidades de ganar',
      cssClass: 'normalAlertHeight',
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Espera un momento por favor. Leyendo factura...'
    });
    await loading.present();
    return loading;
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: 'Cargando...'
    });
    await loading.present();
    return loading;
  }

  async hideLoading() {
    return await this.loadingController.dismiss()
  }

  getMall(domain) {
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/mall/" + domain;
      this.httpAngular.get(url).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting mall", err);
        reject(err);
      })
    })
  }

  //Function to get advertising images
  getAdvertising(title, { id }) {
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/configuration/advertisings?contest=" + id;
      this.httpAngular.get(url).subscribe((response) => {
        for (let add of response.json()) {
          if (add.title == title)
            return resolve(add)
        }
      }, err => {
        console.log("Error Getting advertising", err);
        reject(err);
      })
    })
  }

  //Function to upload bill no images
  uploadBill(body, headers) {
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/bill";
      this.httpAngular.post(url, body, { headers: headers }).subscribe((response) => {
        this.stepPercentage = 100;
        return resolve(response.json().bill_number)
      }, err => {
        console.log("Error Uploading bill", err);
        reject(err);
      })
    })
  }

  getContests(mall_sn, headers) {
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/contest?mall=" + mall_sn;
      this.httpAngular.get(url, { headers: headers }).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting contests", err);
        reject(err);
      })
    })
  }

  getActiveContests(mall_sn) {
    let body = { "mall": mall_sn }
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/active-contests";
      this.httpAngular.get(url, { params: body }).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting contests", err);
        reject(err);
      })
    })
  }

  getActiveLastsContests(mall_sn) {
    let body = { "mall": mall_sn }
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/active-lasts-contests";
      this.httpAngular.get(url, { params: body }).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting contests", err);
        reject(err);
      })
    })
  }

  getStoresByContest(mall, contest, headers) {
    return new Promise((resolve, reject) => {
      let body = {
        "mall": mall,
        "contest": contest
      }
      let url = SERVER_URL + "/mall/stores-by-contest";
      this.httpAngular.post(url, body, { headers: headers }).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting stores", err);
        reject(err);
      });
    })
  }

  getBills(body, headers) {
    return new Promise((resolve, reject) => {
      let url = SERVER_URL + "/mall/send_bills";
      this.httpAngular.post(url, body, { headers: headers }).subscribe((response) => {
        return resolve(response.json())
      }, err => {
        console.log("Error Getting bills", err);
        reject(err);
      })
    })
  }

  //Function to upload images
  uploadImage(bill_number, image, token) {
    return new Promise(async (resolve, reject) => {
      let url = SERVER_URL + "/mall/billPhoto";
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      //image = await this.optimizeImage(image);

      //var optimized_img = image;

      formData.append("bill_number", bill_number);

      let DataBlob = this.getBlobFile(image)
      formData.append("image", DataBlob, 'image.jpeg');

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if (xhr.status == 201) {
            return resolve("");
          } else {
            console.log(xhr)
            console.log("One Photo Bill could not be upload");
            reject("");
          }
        }

      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader("Authorization", "token " + token);
      xhr.send(formData);
    })
  }

  getBlobFile(data) {
    let block = data.split(";");
    let dataType = block[0].split(":")[1];// In this case "image/jpeg"
    let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."
    let DataBlob = this.b64toBlob(realData, dataType);
    return DataBlob;
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 2048;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  //Function to optimize image
  optimizeImage(originalImg) {
    return new Promise(async function (resolve) {
      var img = document.createElement('img');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var dataURI = canvas.toDataURL('image/jpeg', 0.7);
        resolve(dataURI);
      };
      img.src = originalImg;
    })
  }

  //Function to optimize Image
  resizeImage(originalImg, newWidth, newHeight) {
    return new Promise(async function (resolve) {
      var img = document.createElement('img');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min((newWidth / iw), (newHeight / ih));
        if (newWidth > iw || newHeight > ih)
          scale = 1
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        if (img.width < newWidth || img.height < newHeight)
          ctx.drawImage(img, 0, 0, img.width, img.height);
        else
          ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        var dataURI = canvas.toDataURL('image/jpeg', 0.9);
        resolve(dataURI);
      };
      img.src = originalImg;

    })
  }

  terms_conditions(): Promise<any> {
    let url = SERVER_URL + "/configuration/privacy_docs";
    return this.httpClient.get(url).toPromise()
  }

  //Function to upload products

  upload_products(body, headers): Promise<any> {
    let url = SERVER_URL + "/mall/product";
    return this.httpAngular.post(url, body, { headers: headers }).toPromise()
  }

  getWhatsappNumber(mallId) {
    let url = SERVER_URL + `/mall/whatsapp-number?mall=${mallId}`;
    return this.httpClient.get(url).toPromise();
  }

  searchStoreByMall(mall, searchKey = '') {
    let url = SERVER_URL + '/mall/stores';
    let body = {
      mall,
      searchKey
    }
    return this.httpClient.post(url, body).toPromise();
  }

  createUnregisteredUserBill(azureResponse, form, emptyFields, headers) {
    let url = SERVER_URL + '/mall/unfinished-bills';
    let body = {
      azure_response: azureResponse,
      form_fields: form,
      empty_fields: emptyFields
    }
    return this.httpClient.post(url, body, { headers: headers }).toPromise();
  }

}