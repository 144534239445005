import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.page.html',
  styleUrls: ['./store-list.page.scss'],
})
export class StoreListPage implements OnInit {

  constructor(public modalController: ModalController,
              public configProvider: ConfigService) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
