import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

//MODALS
import {ModalTermsPage} from './modal-terms/modal-terms.page';
import {ModalTermsPageModule} from './modal-terms/modal-terms.module';
import { TutorialPageModule } from './modals/tutorial/tutorial.module'
import { TutorialPage } from './modals/tutorial/tutorial.page'
import { StoreListPage } from './modals/store-list/store-list.page';
import { NgCircleProgressModule } from 'ng-circle-progress';
//PLUGINS
import { IonicStorageModule } from '@ionic/storage';
import { StoreListPageModule } from './modals/store-list/store-list.module';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [ModalTermsPage, TutorialPage, StoreListPage],
  imports: [BrowserModule, BrowserAnimationsModule, 
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot(), 
    AppRoutingModule, 
    HttpModule, 
    HttpClientModule, 
    ModalTermsPageModule, 
    TutorialPageModule,
    StoreListPageModule,
    IonicStorageModule.forRoot(),
    NgCircleProgressModule.forRoot({
      "radius": 30,
      "space": 2,
      "maxPercent": 100,
      "outerStrokeWidth": 2,
      "innerStrokeWidth": 2})
    ],
  providers: [
    StatusBar,
    SplashScreen,
    Title,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey
    }
      
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
