import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.page.html',
  styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage implements OnInit {
  stopShowingModal: Boolean;

  constructor(
    public modalController: ModalController, 
    private storage: Storage
  ) { }

  ngOnInit() {
  }

  //Function to close modal
  closeModal() {
    this.modalController.dismiss();
  }

  //Function to not show againg modal
  notShowAgainModal(){
    this.storage.set('stopShowingModal', this.stopShowingModal)
  }
}
